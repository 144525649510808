
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachPlayersNavigationVue from "@/components/coach/players/navigation.vue";
import AppCoachPlayersRootListIndexVue from "@/views/app/coach/players/root/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachPlayersNavigationVue,
    AppCoachPlayersRootListIndexVue,
  },
})
export default class AppCoachPlayersRootIndexVue extends Vue {
}
